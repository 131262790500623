<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>疾病管理</el-breadcrumb-item>
      <el-breadcrumb-item>疾病列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="add('new', '')" style="margin-right: 10px" size="small"
        >添加</el-button
      >
      <!-- 查询疾病： -->
      <el-input
        placeholder="请输入疾病名"
        clearable
        prefix-icon="el-icon-search"
        v-model="search"
        style="width: 200px"
        @clear="searchclear"
        size="small"
      >
      </el-input>
      <el-button
        type="primary"
        style="margin-left: 10px"
        size="small"
        @click="query"
        >查询</el-button
      >
    </div>
    <el-table :data="list" border style="width: 100%" height="1000">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="name" label="疾病"></el-table-column>
      <el-table-column width="200">
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="add('bj', scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="del(scope.row, scope.$index)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column width="280">
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-service"
                @click="addCust(scope.row)"
                >相关患者</el-button
              >
              <el-button
                size="mini"
                type="success"
                icon="el-icon-star-on"
                @click="addCase(scope.row)"
                >相关好转病例
              </el-button>
            </p>
            <p>
              <el-button
                size="mini"
                type="warning"
                icon="el-icon-edit-outline"
                @click="addDoc(scope.row)"
                >相关医生
              </el-button>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="260">
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-document"
              @click="illbaike(scope.row, scope.$index)"
              >疾病百科
            </el-button>
            <el-button
              size="mini"
              type="info"
              icon="el-icon-picture"
              @click="illpics(scope.row, scope.$index)"
              >轮播图
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form :model="form" class="tcform" label-width="170px">
        <el-form-item label="疾病">
          <el-input
            style="float: left"
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入疾病"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="疾病图片">
          <upload :src.sync="form.pic"></upload>
        </el-form-item>
        <el-form-item label="平台内科室" prop="dp_id">
          <el-select
            filterable
            style="width: 60%; float: left"
            v-model="form.dp_id"
            placeholder="请选择平台内科室"
          >
            <el-option
              v-for="item in keshi"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            style="float: left"
            type="number"
            v-model="form.sort"
            autocomplete="off"
            placeholder="请输入数字"
          >
          </el-input>
          <span style="float: left; color: #909399">数字越大排名越靠前</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            form = {};
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload from "../components/upload";
export default {
  components: {
    upload,
  },
  inject: ["reload"],
  data() {
    return {
      list: [],
      form: {
        name: "",
        pic: "",
        sort: "",
        dp_id: "",
      },
      dialogFormVisible: false,
      title: "添加疾病",
      type: "",
      item: "",
      keshi: [],
      search: "",
    };
  },
  mounted() {
    this.getdata();
    this.axios
      .get("/gu/get_gu_department")
      .then((res) => {
        if (res.data.code == 0) {
          this.keshi = res.data.result;
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    getdata() {
      this.axios
        .get("/gu/get_gu_ill_lib")
        .then((rs) => {
          if (rs.data.code == 0) {
            this.list = rs.data.result;
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add(type, item) {
      // console.log(item);
      this.type = type;
      this.item = item;
      this.dialogFormVisible = true;
      if (type != "new") {
        this.title = "编辑疾病";
        this.form.name = item.name;
        this.form.pic = item.pic;
        this.form.sort = item.sort;
        this.form.dp_id = item.dp_id;
      } else {
        this.title = "添加疾病";
        this.form.name = "";
        this.form.pic = "";
        this.form.sort = "";
        this.form.dp_id = "";
      }
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该疾病, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/gu/delete_gu_ill_lib", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    sub() {
      if (
        this.form.sort == "" ||
        this.form.sort == null ||
        this.form.sort == undefined
      ) {
        this.form.sort = 0;
      }
      let url = "";
      let str = "";
      if (this.type == "new") {
        url = "/gu/insert_gu_ill_lib";
        str = "添加成功";
      } else {
        url = "/gu/update_gu_ill_lib";
        str = "修改成功";
        this.form.id = this.item.id;
      }
      // console.log(this.form);
      this.axios
        .post(url, this.qs.stringify(this.form))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.dialogFormVisible = false;
            this.form = {};
            this.$message({
              message: str,
              type: "success",
            });
            this.reload();
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addDoc(row) {
      this.$router.push({
        path: "/illnessManage/illAddDoc",
        query: { id: row.id, name: row.name },
      });
    },
    illbaike(row, index) {
      this.$router.push({
        path: "/illnessManage/illCycDetail",
        query: { id: row.id, name: row.name },
      });
    },
    illpics(row, id) {
      this.$router.push({
        path: "/illnessManage/illnessPics",
        query: { id: row.id, name: row.name },
      });
    },
    query() {
      this.list = [];
      this.axios
        .get("/gu/get_gu_ill_lib_like_name?ill_lib_name=" + this.search)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchclear() {
      this.getdata();
    },
    addCust(row) {
      this.$router.push({
        path: "/illnessManage/illAddCust",
        query: { ill_id: row.id, name: row.name },
      });
    },
    addCase(row) {
      this.$router.push({
        path: "/illnessManage/illAddCase",
        query: { ill_id: row.id, name: row.name },
      });
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.tcform {
  width: 80%;
}

.el-table__body tr.current-row > td {
  background: rgb(198, 226, 255) !important;
}

.addcust table thead th:nth-child(1) {
  opacity: 0;
}
.addcust .el-dialog {
  margin-top: 5vh !important;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>